@import url(https://fonts.googleapis.com/css?family=Montserrat);
/*@import url('https://fonts.googleapis.com/css?family=ABeeZee|Maven+Pro|Quattrocento+Sans|Roboto|Source+Sans+Pro|Montserrat');*/

@font-face {
    font-family: 'Montserrat-Light';
    src: local('Montserrat-Light'), local('Montserrat-Light'), url(/static/media/Montserrat-Light.2f354053.ttf) format('truetype');
}
@font-face {
    font-family: 'Montserrat-Regular';
    src: local('Montserrat-Regular'), local('Montserrat-Regular'), url(/static/media/Montserrat-Regular.a8a11736.ttf) format('truetype');
}
@font-face {
    font-family: 'Montserrat-Bold';
    src: local('Montserrat-Bold'), local('Montserrat-Bold'), url(/static/media/Montserrat-Bold.a3b387c9.ttf) format('truetype');
}

/*================> Global Configuration <================*/
body {
    background-color: rgb(1,13,39);
    font-family: 'Montserrat-Light', Helvetica !important;
    font-weight: 300;
    color: #fff;
    line-height: 1.6em;
    letter-spacing: 3.8px;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -webkit-overflow-scrolling: touch;
    margin: 0%;
    font-style: normal;
    height: 100vh;
}

h1{
    font-family: 'Montserrat-Regular', Helvetica-Light !important;
    font-weight: normal;
}

h2 {
    font-family: 'Montserrat-Light', Helvetica-Light !important;
    font-weight: normal;
}
.main {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.logo-box{
    padding-top: 100px;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    overflow: hidden;
    width: 210px;
    height: 210px;
}
.logo {
    width: 250px;
    height: 200px;
    background-image: url(/static/media/PB-Logo-Original-Transparent-x4200.8004cc79.png);
    background-size: 210px;
    background-repeat: no-repeat;
}

/* social */
.social {
    padding: 30px 0 0 0;
}

.social a {
    font-size: 24px;
    color: #fff;
    padding: 0 5px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.social a:hover {
    color: #0080fb;
}
